import React, { useState } from "react";
import Layout from "../../components/Layout/Layout";
import { SEO } from "../../components/seo";
import Faqs from "../../components/Faqs/Faqs";

const FaqsPage = () => {
  const [faqDescription, setFaqDescription] = useState<string>("");

  return (
    <>
      <Head faqDescription={faqDescription} />
      <Layout>
        <Faqs pageTitle="FAQs page" setFaqDescription={setFaqDescription} />
      </Layout>
    </>
  );
};

const Head = ({ faqDescription }: { faqDescription: string }) => {
  console.log(faqDescription);
  return <SEO title="FAQs Page" description={faqDescription} />;
};

export default FaqsPage;
